@import "../../global.scss";


.contact{
    background-color: black;
    color:white;
    

    

    ul{
        position:relative;
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction:column;
        top:30%;

        @include mobile{
            top:20%;   
            right:5%;  
        }
        
        li{
            margin-top:15px;
            font-size: 45px;

            @include mobile{
                font-size: 25px;      
            }
        }

        h3{
            margin-bottom:20px;
        }
    }

    .logo{
        text-decoration:none;
        color:white;

        &:hover{
            font-weight:800;
        }
    }
    
    .mail{
        cursor:pointer;
        
        &:hover{
            font-weight:800;
        }
    }

    .phone{
        cursor:pointer;

        &:hover{
            font-weight:800;
        }
    }

    .discord{
        cursor:pointer;

        &:hover{
            font-weight:800;
        }
    }

    
}