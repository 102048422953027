@import "../../global.scss";

.portfolioList{
    font-size:20px;
    margin-right:50px;
    padding:7px;
    border-radius:10px;
    cursor:pointer;
    color:white;
    
    @include mobile{
        margin-right:10px;       
    }

    &.active{
        background-color:$mainColor;
        
    }
}