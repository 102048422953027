@import "../../global.scss";

.portfolio{
    background-color: black;
    display:flex;
    flex-direction:column;
    align-items:center;
    color:white;
    
    
    h1{
        position:relative;
        top:20%;
        font-size:50px;

        @include mobile{
            font-size: 20px;       
        }
    }

    ul{
        margin:10px;
        padding:0;
        list-style:none;
        display:flex;
        position:relative;
        top:20%;

        @include mobile{
            flex-wrap:wrap;
            justify-content:center;
            margin: 10px 0;       
        }

        
    }

    .container{
        width:70%;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-wrap:wrap;
        position:relative;
        top:25%;
        
        @include mobile{
            width:100%; 
                  
        }

        .item{
            width: 220px;
            height:150px;
            border-radius:20px;
            border:1px solid rgb(21, 21, 21);
            margin:10px 20px;
            display:flex;
            justify-content:center;
            align-items:center;
            color:white;
            position:relative;
            transition:all 0.5s ease;
            cursor:pointer;

            @include mobile{
                width:80px;
                height:60px;
                border-radius:10px;
                
            }

            h3{
                position:absolute;
                font-size:20px;

                @include mobile{
                    font-size: 10px;
                    font-weight:300;
                }
            }

            img{
                width: 100%;
                height: 100%;
                object-fit:cover;
                z-index:1;
            }

            &:hover{
                background-color:$mainColor;

                img{
                    opacity:0.2;
                    z-index:0;
                    
                }
            }
        }

    }
    /*--------------------------------------------FOR MOBILE DEVICES-------------------------------------------------------------*/
    
    
    
    
}