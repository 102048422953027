@import "../../global.scss";

.intro{
    background-color: black;
    display: flex;


    @include mobile{
        flex-direction:column;
        align-items:center;
    }


    .left{
        flex:0.5;
        overflow:hidden;
        .imgContainer{
            
            
            width:1100px;
            height:1100px;

            

            border-radius:50%;
            display:flex;
            align-items:center;
            @include mobile{
                align-items:flex-start;
            }
            justify-content: flex-end;
            float:right;

            

            img{
                height:50%;

                @include mobile{
                    position:relative;
                    height:20%;
                    right:430px;
                    top:50px;
                }
                
            }
        }
        
    }

    .right{
        flex:0.5;
        position:relative;

        .wrapper{
            width: 100%;
            height: 100%;
            padding-left:50px;
            display:flex;
            flex-direction:column;
            justify-content:center;

            @include mobile{
                padding-left:0;
                align-items:center;
            }

            h1{
                font-size:90px;
                margin:10px 0;
                color:white;
                @include mobile{
                    font-size:50px
                }
            }

            h2{
                font-size:50px;
                color:white;

                @include mobile{
                    font-size:30px
                }
            }

            h3{
                font-size:30px;
                color:white;
                @include mobile{
                    font-size:20px
                }

                

                span{
                    font-size:inherit;
                    color:crimson;
                }

                .ityped-cursor{
                    animation:blink 1s infinite;
                }
                
                @keyframes blink{
                    50%{
                        opacity:0;
                    }

                    100%{
                        opacity:1;
                    }
                }
            }

            
        }

        a{
            position:absolute;
            bottom:10px;
            left:40%;
            @include mobile{
                left:50%;
            }
            

            img{
                width:30px;
                background-color:white;
                border-radius:20%;
                animation: arrowBlink 2s infinite;
            }

            @keyframes arrowBlink{
                50%{
                    opacity:0;
                }

                100%{
                    opacity:100%;
                }
            }
        }

    }


/*------------------------------------------FOR MOBILE DEVICES------------------------------------------------------------*/

   
}
/*------------------------------------------------------------------------------------------------------------------------*/